import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Typography, Box, useTheme, Button, Link, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "components/elements/Card";
import PageTitle from "components/elements/PageTitle";
import Panel from "components/elements/Panel";
import { AccountingCoinsStack, AwardTrophyStar } from "components/icons";
import { LikeShine } from "components/icons/components/LikeShine";
import CreditsBundles from "components/modules/CreditsBundles";
import Subscription from "components/modules/Subscription";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useIsLogin } from "hooks/useUser";
import { Trans, useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { getAnyByMode } from "utils";

const Pricing = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const isLoggedIn = useIsLogin();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item>
          <Card header={t("pricingPage.title")}>
            <>
              <PageTitle i18nKey="pricingPage.meta_title" />
              <p>
                <Trans
                  i18nKey={"pricingPage.howToAcquireCreditsText1"}
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
              <p>
                <Trans i18nKey={"pricingPage.howToAcquireCreditsText2"} />
              </p>
              <p>
                <Trans
                  i18nKey={"pricingPage.howToAcquireCreditsText3"}
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
              <Typography variant="h4" component="h2" sx={{ mt: 6, mb: 2, textAlign: "center" }} color="secondary">
                {t("pricingPage.subscriptions")}
              </Typography>
              <Subscription cta={false} />
              <Box id="jak-ziskat">
                <Typography variant="h4" component="h2" sx={{ mt: 6, mb: 4, textAlign: "center" }} color="secondary">
                  {t("pricingPage.howToAcquireCredits")}
                </Typography>
                <Grid container justifyContent="center" textAlign="center" spacing={4}>
                  <Grid item xs={12} lg={6}>
                    <Panel sx={{ pt: 2, px: 2, height: "100%" }} background={getAnyByMode(theme, undefined, colors.gray50)}>
                      <Box sx={{ fontWeight: "bold", color: theme.palette.secondary.contrastText }}>
                        {t("pricingPage.badges")}
                      </Box>
                      <Box sx={{ textAlign: "center", py: 2 }}>
                        <Box>
                          <AwardTrophyStar sx={{ fontSize: 40, color: "#aaa", mt: 1, mb: 3 }} />
                        </Box>
                        <Box sx={{ fontSize: "0.9em", color: theme.palette.secondary.contrastText, mb: 2 }}>
                          {t("pricingPage.badgesFeatures")}
                        </Box>
                      </Box>
                    </Panel>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Panel sx={{ pt: 2, px: 2, height: "100%" }} background={getAnyByMode(theme, undefined, colors.gray50)}>
                      <Box sx={{ fontWeight: "bold", color: theme.palette.secondary.contrastText }}>
                        {t("pricingPage.recommend")}
                      </Box>
                      <Box sx={{ textAlign: "center", py: 2 }}>
                        <Box>
                          <LikeShine sx={{ fontSize: 40, color: "#aaa", mt: 1, mb: 3 }} />
                        </Box>
                        <Box sx={{ fontSize: "0.9em", color: theme.palette.secondary.contrastText, mb: 2 }}>
                          {t("pricingPage.recommendFeatures")}
                        </Box>
                      </Box>
                    </Panel>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Panel sx={{ pt: 2, px: 2, height: "100%" }} background={getAnyByMode(theme, undefined, colors.gray50)}>
                      <Box sx={{ fontWeight: "bold", color: theme.palette.secondary.contrastText }}>
                        {t("pricingPage.rechargeCredits")}
                      </Box>
                      <Box sx={{ textAlign: "center", py: 2 }}>
                        <Box>
                          <AccountingCoinsStack sx={{ fontSize: 40, color: "#aaa", mt: 1, mb: 3 }} />
                        </Box>
                        <Box sx={{ fontSize: "0.9em", color: theme.palette.secondary.contrastText, mb: 2 }}>
                          <Trans i18nKey={"pricingPage.rechargeCreditsFeatures"} />
                        </Box>
                        <Button
                          href="/mesicni-platba"
                          size="small"
                          variant="contained"
                          sx={{ mt: { xs: 0, lg: 2 } }}>
                          {t("pricingPage.rechargeCreditsButton")}
                        </Button>
                      </Box>
                    </Panel>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Panel sx={{ pt: 2, px: 2, height: "100%" }} background={getAnyByMode(theme, undefined, colors.gray50)}>
                      <Box sx={{ fontWeight: "bold", color: theme.palette.secondary.contrastText }}>
                        {t("pricingPage.buyCredits")}
                      </Box>
                      <Box sx={{ textAlign: "center", py: 2 }}>
                        <Box>
                          <AccountingCoinsStack sx={{ fontSize: 40, color: "#aaa", mt: 1, mb: 3 }} />
                        </Box>
                        <Box sx={{ fontSize: "0.9em", color: theme.palette.secondary.contrastText, mb: 2 }}>
                          <Trans i18nKey={"pricingPage.buyCreditsFeatures"} />
                        </Box>
                        <Button
                          href="#balíčky"
                          size="small"
                          variant="contained"
                          endIcon={<KeyboardArrowDown />}
                          sx={{ mt: { xs: 0, lg: 2 } }}>
                          {t("pricingPage.showBundles")}
                        </Button>
                      </Box>
                    </Panel>
                  </Grid>
                  {!isLoggedIn && (
                    <Grid item xs={12}>
                      <Box sx={{ mt: 2 }}>
                        <Trans i18nKey="pricingPage.registerText" />
                      </Box>
                      <Box sx={{ textAlign: "center", py: 2 }}>
                        <Button component={RouterLink} variant="contained" to="/registrace">
                          {t("pricingPage.registerCta")}
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          </Card>
          <Card header={t("creditsBuyTitle3")} id="balíčky">
            <Typography variant="subtitle2" fontWeight={400}>
              <Box sx={{ mb: 3 }}>
                <Trans
                  i18nKey="creditsBuySubtitle3"
                  components={{
                    a1: <Link href={"/predplatne"} />,
                    a2: <Link href={"/proc-bitcoin/cashback-saty"} />,
                    a3: <Link href={"/mesicni-platba"} />,
                  }}
                />
              </Box>
            </Typography>
            {!isMobile && <CreditsBundles buyEnabled={isLoggedIn} />}
          </Card>
          {isMobile && (
            <Box sx={{ mt: -6, pb: 6 }}>
              <CreditsBundles buyEnabled={isLoggedIn} />
            </Box>
          )}
          <Card header={t("pricingPage.affiliate")}>
            <p>
              <Trans
                i18nKey="pricingPage.affiliateText"
                components={{
                  a: <Link to="/stosaky" component={RouterLink} />,
                }}
              />
            </p>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Pricing;
