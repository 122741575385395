import React, { useState } from "react";

import ClickAwayListener from "@mui/base/ClickAwayListener";
import { Tooltip as MUITooltip, TooltipProps, useMediaQuery, useTheme } from "@mui/material";

type IProps = TooltipProps;

const POSITIVE_INFINITY = 99999999999999;

const Tooltip: React.FC<IProps> = ({ children, placement, ...props }) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOnOpen = (_event: React.SyntheticEvent) => setOpen(true);

  const handleOnClickAway = () => setOpen(false);

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <MUITooltip
          {...props}
          leaveTouchDelay={POSITIVE_INFINITY}
          enterTouchDelay={1}
          open={open}
          onOpen={handleOnOpen}>
          {children}
        </MUITooltip>
      </ClickAwayListener>
    );
  }

  return (
    <MUITooltip {...props} placement={placement}>
      {children}
    </MUITooltip>
  );
};

export default Tooltip;
