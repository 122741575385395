import { Typography, Box, useMediaQuery, useTheme, Link } from "@mui/material";
import Card from "components/elements/Card";
import CreditsBundles from "components/modules/CreditsBundles";
import { useTranslation, Trans } from "react-i18next";
import { ConditionalWrapper } from "utils";

import Recharge from "./Recharge";

const CreditsBuyPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const headerContent = () => {
    return (
      <>
        <Typography id={"nakoupit"} variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
          {t("creditsBuyTitle1")}
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 3 }}>
          <Box>
            <Trans i18nKey="creditsBuySubtitle1" />
          </Box>
        </Typography>
        <Recharge />
        <Typography variant="h5" component="h1" fontWeight={600} sx={{ pb: 1, color: "tertiary.main" }}>
          {t("creditsBuyTitle3")}
        </Typography>
        <Typography variant="subtitle2" fontWeight={400} sx={{ pb: 3 }}>
          <Box>
            <Trans
              i18nKey="creditsBuySubtitle3"
              components={{
                a1: <Link href={"/predplatne"} />,
                a2: <Link href={"/proc-bitcoin/cashback-saty"} />,
                a3: <Link href={"/mesicni-platba"} />,
              }}
            />
          </Box>
        </Typography>
      </>
    );
  };

  return (
    <>
      <ConditionalWrapper condition={!isMobile} wrapper={(children) => <Card sx={{ mb: 4 }}>{children}</Card>}>
        <>
          <CreditsBundles buyEnabled={true}>
            {headerContent()}
          </CreditsBundles>
        </>
      </ConditionalWrapper>
    </>
  );
};

export default CreditsBuyPage;
