import React from "react";

import { Avatar, Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import imageSats from "assets/images/sats.png";
import Badge from "components/elements/Badge";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { BarLineChart, CoinsStacked, Users } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { CashbackSatsType } from "types/enums";
import { ICashbackSats } from "types/types";
import { renderNumber, renderTime } from "utils/formatter";

interface IProps {
  transaction: ICashbackSats;
  isLast: boolean;
}

const TransactionsItem: React.FC<IProps> = ({ transaction, isLast }) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(370));

  const getIcon = (type: CashbackSatsType) => {
    switch (type) {
      case CashbackSatsType.GRID:
        return <BarLineChart color="inherit" sx={{ fontSize: 20 }} />;
      case CashbackSatsType.DCA:
        return <CoinsStacked color="inherit" sx={{ fontSize: 20 }} />;
      case CashbackSatsType.AFFILIATE:
        return <Users color="inherit" sx={{ fontSize: 20 }} />;
    }
  };

  const getBadge = (type: CashbackSatsType) => {
    switch (type) {
      case CashbackSatsType.GRID:
        return (
          <Badge color="info" withTextTransform={false}>
            <Typography fontSize={".75rem"}>{t("cashbackSatsList.type.g")}</Typography>
          </Badge>
        );
      case CashbackSatsType.DCA:
        return (
          <Badge color="success" withTextTransform={false}>
            <Typography fontSize={".75rem"}>{t("cashbackSatsList.type.d")}</Typography>
          </Badge>
        );
      case CashbackSatsType.AFFILIATE:
        return (
          <Badge color="purple" withTextTransform={false}>
            <Typography fontSize={".75rem"}>{t("cashbackSatsList.type.a")}</Typography>
          </Badge>
        );
    }
  };

  return (
    <Stack
      borderBottom={!isLast ? `1px solid ${colors.veryLightGray}` : undefined}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      py={1}>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={{ xs: 1, md: 6 }}
        alignItems={isMobile ? "start" : "center"}>
        <Box minWidth={50}>
          <Typography color={colors.gray400} fontSize={".875rem"}>
            {renderTime(transaction.d)}
          </Typography>
        </Box>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <Box display={"flex"} justifyContent={"center"} width={"24px"}>
            {getIcon(transaction.t)}
          </Box>
          {getBadge(transaction.t)}
        </Stack>
      </Stack>
      <Stack direction={"row"} spacing={1} alignItems={"center"} pr={{ xs: 3.8, md: 5.8 }}>
        <MaskedModeWrapper color={colors.darkBlue} fontSize={".875rem"}>
          <Typography color={colors.darkBlue} fontSize={".875rem"}>
            {`+${renderNumber(transaction.s)}`}
          </Typography>
        </MaskedModeWrapper>
        <Avatar src={imageSats} sx={{ width: "16px", height: "16px" }} />
      </Stack>
    </Stack>
  );
};

export default TransactionsItem;
