import { useEffect } from "react";

import { setWithExpiry } from "hooks/useTTLLocalStorage";
import { useLocation } from "react-router";
import { StringParam, useQueryParams } from "use-query-params";

const ScrollToTop: React.FC = ({ children }) => {
  const [query, setQuery] = useQueryParams({ aff: StringParam });
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if (query?.aff) {
      setWithExpiry("affBy", query.aff);
      setQuery({ aff: undefined });
    }
  }, [query, setQuery]);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      // else scroll to id
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return <>{children}</>;
};

export default ScrollToTop;
