import React from "react";

import { Box, Stack } from "@mui/material";
import Card2 from "components/elements/Card2";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import dayjs from "dayjs";
import { useDeepCompareEffect } from "react-use";
import { CashbackSatsType } from "types/enums";
import { ICashbackSatsList } from "types/types";

import TransactionCard from "./Card";
import TransactionsItem from "./Item";
import IProps, { ICashbackSatsTransaction } from "./types";

const groupDataByMonthAndDay = (data: ICashbackSatsList) => {
  const result: ICashbackSatsTransaction[] = [];

  data.cashbackSats.forEach((item) => {
    const mDate = dayjs(item.d).startOf("month").format("YYYY-MM-DD");
    const dDate = dayjs(item.d).format("YYYY-MM-DD");
    const index = result.findIndex((x) => x.d === mDate);

    if (index === -1) {
      result.push({
        d: mDate,
        sum: item.s,
        aCount: item.t === CashbackSatsType.AFFILIATE ? 1 : 0,
        dCount: item.t === CashbackSatsType.DCA ? 1 : 0,
        gCount: item.t === CashbackSatsType.GRID ? 1 : 0,
        items: [
          {
            d: dDate,
            sum: item.s,
            aCount: item.t === CashbackSatsType.AFFILIATE ? 1 : 0,
            dCount: item.t === CashbackSatsType.DCA ? 1 : 0,
            gCount: item.t === CashbackSatsType.GRID ? 1 : 0,
            items: [item],
          },
        ],
      });
    } else {
      result[index].sum += item.s;
      result[index].aCount += item.t === CashbackSatsType.AFFILIATE ? 1 : 0;
      result[index].dCount += item.t === CashbackSatsType.DCA ? 1 : 0;
      result[index].gCount += item.t === CashbackSatsType.GRID ? 1 : 0;

      const index2 = result[index].items.findIndex((x) => x.d === dDate);

      if (index2 === -1) {
        result[index].items.push({
          d: dDate,
          sum: item.s,
          aCount: item.t === CashbackSatsType.AFFILIATE ? 1 : 0,
          dCount: item.t === CashbackSatsType.DCA ? 1 : 0,
          gCount: item.t === CashbackSatsType.GRID ? 1 : 0,
          items: [item],
        });
      } else {
        result[index].items[index2].sum += item.s;
        result[index].items[index2].aCount += item.t === CashbackSatsType.AFFILIATE ? 1 : 0;
        result[index].items[index2].dCount += item.t === CashbackSatsType.DCA ? 1 : 0;
        result[index].items[index2].gCount += item.t === CashbackSatsType.GRID ? 1 : 0;
        result[index].items[index2].items.push(item);
      }
    }
  });

  return result;
};

const Transactions: React.FC<IProps> = ({ data }) => {
  const { colors } = useMUIThemeModeContext();
  const [dataByMonth, setDataByMonth] = React.useState<ICashbackSatsTransaction[]>([]);

  useDeepCompareEffect(() => {
    setDataByMonth(groupDataByMonthAndDay(data));
  }, [data]);

  return (
    <Card2 height={"100%"}>
      <Stack spacing={3}>
        {dataByMonth.map(({ items: itemsM, ...summaryM }, indexM) => (
          <TransactionCard key={`${summaryM.d}-${indexM}`} summary={summaryM} defaultOpen={indexM === 0}>
            <Stack spacing={1} pt={2}>
              {itemsM.map(({ items: itemsD, ...summaryD }, indexD) => (
                <Box
                  key={`${summaryD.d}-${indexD}`}
                  py={{ xs: 0.5, md: 1 }}
                  px={{ xs: 1, md: 3 }}
                  borderRadius={"8px"}
                  bgcolor={colors.gray50}>
                  <TransactionCard summary={summaryD} defaultOpen={indexM === 0 && indexD === 0} isSub>
                    <Stack pt={1.5}>
                      {itemsD.map((transaction, index) => (
                        <TransactionsItem
                          key={`${transaction.d}-${index}`}
                          transaction={transaction}
                          isLast={itemsD.length - 1 === index}
                        />
                      ))}
                    </Stack>
                  </TransactionCard>
                </Box>
              ))}
            </Stack>
          </TransactionCard>
        ))}
      </Stack>
    </Card2>
  );
};

export default Transactions;

