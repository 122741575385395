import { Box, Grid, IconButton } from "@mui/material";
import Card2 from "components/elements/Card2";
import { Settings3 } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useTranslation } from "react-i18next";
import { SubscriptionType } from "types/enums";
import { IStrategiesSummary } from "types/types";
import { hasAllValuesFalse } from "utils";

import Item from "./Item";
import CashbackSatsItem from "./Item/CashbackSats";
import SkeletonItem from "./Item/Skeleton";

interface IProps {
  data?: IStrategiesSummary;
  onOpenModal: () => void;
}

const Summaries = ({ data, onOpenModal }: IProps) => {
  const { t } = useTranslation<string>();
  const { colors } = useMUIThemeModeContext();
  const { summariesSettings, subscriptionType } = useUserDataContext();

  const hasEmptySummaries = () => {
    return (
      data?.dcaStrategiesSummaries
      && !data?.dcaStrategiesSummaries.length
      && data?.gridStrategiesSummaries
      && !data?.gridStrategiesSummaries.length
    );
  };

  if (hasAllValuesFalse(summariesSettings) || subscriptionType !== SubscriptionType.PREMIUM || hasEmptySummaries()) return null;

  const getList = () => {
    const summaryList: React.ReactNode[] = [];

    data?.gridStrategiesSummaries.forEach((summary, index) =>
      summaryList.push(<Item summary={summary} key={`GridSummaryItem-${index}`} isGrid />)
    );
    data?.dcaStrategiesSummaries.forEach((summary, index) =>
      summaryList.push(<Item summary={summary} key={`DcaSummaryItem-${index}`} />)
    );

    return summaryList;
  };

  return (
    <Card2
      p={1.5}
      mb={4}
      height={"100%"}
      title={t("summaries.title")}
      action={
        <IconButton size="small" onClick={onOpenModal}>
          <Settings3 sx={{ fontSize: 18, color: colors.gray700 }} />
        </IconButton>
      }
      childrenBoxSx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
      }}
      headerStackSx={{
        mb: 2,
      }}>
      <Box width={"100%"}>
        <Grid container spacing={2}>
          {summariesSettings.cashbackSats && <CashbackSatsItem />}
          {data ? getList() : <SkeletonItem canRenderCashbackSats={summariesSettings.cashbackSats} />}
        </Grid>
      </Box>
    </Card2>
  );
};

export default Summaries;
