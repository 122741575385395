import React from "react";

import { ColoredBox } from "./styles";
import IProps from "./types";

const Badge: React.FC<IProps> = ({ color, children, withTextTransform = true }) => {
  return <ColoredBox customColor={color} withTextTransform={withTextTransform}>{children}</ColoredBox>;
};

export default Badge;
