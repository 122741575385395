import { useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import CenterWrapper from "components/elements/CenterWrapper";
import PageTitle from "components/elements/PageTitle";
import BotsList from "components/modules/BotsList";
import CashbackSatsList from "components/modules/CashbackSatsList";
import ExchangeConnectionsList from "components/modules/ExchangeConnectionsList";
import WithdrawalConnectionsList from "components/modules/WithdrawalConnectionsList";
import { useUserDataContext } from "context/UserDataContext";
import {
  useAPIExchangeConnectionsList,
  useAPIDcaBotsList,
  useAPIBadgeTypes,
  useAPIWithdrawalConnectionsList,
  useAPIGridBotsList,
  useAPIDashboardStrategies,
} from "hooks/useAPI";
import { SubscriptionType } from "types/enums";
import { isEmptyArray } from "utils";

import Community from "./Community";
import HowStart from "./HowStart";
import { CustomBox } from "./styles";
import SubscriptionInfo from "./SubscriptionInfo";

const DashboardPage = () => {
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const { subscriptionType, creditsBalance, creditsUnclaimed, badgesCount, refreshData, summariesSettings } = useUserDataContext();
  const {
    data: exchangeConnectionsData,
    isValidating: exchangeConnectionsIsLoading,
    mutate: exchangeConnectionsMutate,
    error: exchangeConnectionsError,
  } = useAPIExchangeConnectionsList();
  const { data: dcaBotsData, mutate: dcaBotsMutate, error: dcaBotsError } = useAPIDcaBotsList();
  const { data: botsData, mutate: botsMutate, error: botsDataError } = useAPIGridBotsList();
  const { data: badgeTypesData, error: badgeTypesError } = useAPIBadgeTypes();
  const {
    data: withdrawalConnectionsData,
    isValidating: withdrawalConnectionsIsLoading,
    mutate: withdrawalConnectionsMutate,
  } = useAPIWithdrawalConnectionsList();
  const { data: dashboardStrategiesData } = useAPIDashboardStrategies(
    subscriptionType !== undefined && subscriptionType !== SubscriptionType.FREE,
    subscriptionType === SubscriptionType.PREMIUM ? summariesSettings : undefined
  );

  const isPossibleRenderBotList = () => {
    if (!exchangeConnectionsData) return false;
    if (isEmptyArray(exchangeConnectionsData?.exchangeConnections)) return false;

    return true;
  };

  const hasBots = () => {
    if (!isEmptyArray(dcaBotsData?.dcaBots) || !isEmptyArray(botsData?.bots)) return true;

    return false;
  };

  const isPossibleRenderWithdrawalList = () => {
    if (withdrawalConnectionsData && withdrawalConnectionsData.withdrawalConnections.length > 0) return true;
    if (!dcaBotsData) return false;
    if (isEmptyArray(dcaBotsData?.dcaBots)) return false;

    return true;
  };

  const isFirstLoading = () => {
    return (
      (!exchangeConnectionsData && !exchangeConnectionsError)
      || (!dcaBotsData && !dcaBotsError)
      || (!botsData && !botsDataError)
      || (!badgeTypesData && !badgeTypesError)
      || isFirst
    );
  };

  useEffect(() => {
    //Hack - show loading at least for 0.1s, for a faster redirect to the dashboard
    setTimeout(() => setIsFirst(false), 100);
  }, [])

  useEffect(() => {
    refreshData?.();
  }, [creditsBalance, badgesCount, creditsUnclaimed]);

  if (isFirstLoading()) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  return (
    <CustomBox>
      <PageTitle i18nKey="dashboard.meta_title" />
      {isPossibleRenderBotList() && <SubscriptionInfo />}
      {!hasBots() && <HowStart hasExchangeConnection={isPossibleRenderBotList()} />}
      {isPossibleRenderBotList() && (
        <BotsList
          dcaBotsData={dcaBotsData}
          dcaBotsMutate={dcaBotsMutate}
          botsData={botsData}
          botsMutate={botsMutate}
          strategiesData={dashboardStrategiesData}
        />
      )}
      <CashbackSatsList hasExchangeConnection={isPossibleRenderBotList()} />
      <ExchangeConnectionsList
        data={exchangeConnectionsData}
        isLoading={exchangeConnectionsIsLoading}
        refreshData={async () => {
          await exchangeConnectionsMutate();
          refreshData?.();
        }}
        refreshBotsData={async () => {
          await dcaBotsMutate();
          await botsMutate();
          refreshData?.();
        }}
      />
      {isPossibleRenderWithdrawalList() && (
        <WithdrawalConnectionsList
          data={withdrawalConnectionsData}
          isLoading={withdrawalConnectionsIsLoading}
          refreshData={async () => {
            await withdrawalConnectionsMutate();
            refreshData?.();
          }}
        />
      )}
      <Community />
    </CustomBox>
  );
};

export default DashboardPage;
