import { DISCORD } from "constants/links";
import { rechargeOptions } from "constants/subscriptions";

import React, { useState } from "react";

import { Avatar, Box, Button, CircularProgress, Link, Stack, Typography, useTheme } from "@mui/material";
import { cancelCreditsRecharge, createCreditsRecharge } from "API/calls";
import BackdropLoading from "components/elements/BackdropLoading";
import Card2 from "components/elements/Card2";
import CenterWrapper from "components/elements/CenterWrapper";
import PageTitle from "components/elements/PageTitle";
import { CheckCircle, CheckCircleOutlined } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPICreditsPaymentsRechargeTypes, useAPICreditsRecharge, useAPIUserInvoiceData } from "hooks/useAPI";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";
import { RechargeState, RechargeType } from "types/enums";
import { renderNumber } from "utils/formatter";

import ModalInvoiceSetup from "../Settings/ModalInvoiceSetup";

const NewRecharge: React.FC = () => {
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirm();

  const { data, mutate, response } = useAPICreditsRecharge();
  const { data: userInvoiceData, mutate: userInvoiceRefetch } = useAPIUserInvoiceData();
  const { data: rechargeTypes, mutate: rechargeTypesRefetch } = useAPICreditsPaymentsRechargeTypes();
  const isNewOrActive = data?.state === RechargeState.ACTIVE || data?.state === RechargeState.NEW;

  const [isOpenBackdropLoading, setIsOpenBackdropLoading] = useState<boolean>(false);
  const [modalInvoiceSetupOpen, setModalInvoiceSetupOpen] = useState<boolean>(false);

  if (!data && response?.status !== 204) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  const handleActivate = async (type: RechargeType) => {
    if (data?.state === RechargeState.NEW || data?.state === RechargeState.ACTIVE) {
      handleCancel(() => handleCreate(type));
    } else {
      handleCreate(type);
    }
  };

  const handleCancel = async (callback?: () => void) => {
    try {
      await confirm({
        title: t("recharge.create.cancelActive.title"),
        description: t("recharge.create.cancelActive.description"),
        dialogProps: { maxWidth: "xs" },
      });

      setIsOpenBackdropLoading(true);

      try {
        await cancelCreditsRecharge();

        if(callback) callback();
        else {
          mutate();
          enqueueSnackbar(t("recharge.confirmCancel.cancelSuccess"), { variant: "success" });
          setIsOpenBackdropLoading(false);
        }
      } catch (error: any) {
        if (error?.response?.status === 404) {
          enqueueSnackbar(t("recharge.confirmCancel.error404"), { variant: "error" });
        } else {
          enqueueSnackbar(t("recharge.confirmCancel.cancelError"), { variant: "error" });
        }
        setIsOpenBackdropLoading(false);
      }
    } catch (err: any) {
      /* silent */
    }
  };

  const handleCreate = async (type: RechargeType) => {
    setIsOpenBackdropLoading(true);

    try {
      const { data } = await createCreditsRecharge(type);
      window.location.replace(data?.redirectUrl);
      enqueueSnackbar(t("recharge.create.createSuccess"), { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(t("recharge.create.createError"), { variant: "error" });
    }
    setIsOpenBackdropLoading(false);
  };

  const handleRefresh = () => {
    userInvoiceRefetch();
    rechargeTypesRefetch();
  };

  const solveType = (type: RechargeType, prevType?: RechargeType) => {
    if (isNewOrActive) {
      if (data?.type === prevType) return prevType;

      return type;
    }

    return type;
  };

  const rechargeCard = (type: RechargeType, defaultPrice: number, contains: number[], img: string, prevType?: RechargeType,) => {
    const rechargeType = solveType(type, prevType);
    const paymentType = rechargeTypes?.creditsPaymentTypes?.[rechargeType];

    const isActive = isNewOrActive ? data?.type === rechargeType : false;
    const price = paymentType?.czkAmountWithTax ?? defaultPrice;
    const priceWithoutTax = paymentType?.czkAmountWithoutTax;

    return (
      <Card2
        key={rechargeType}
        childrenBoxSx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        sx={{ border: isActive ? `2px solid ${theme.palette.primary.main}` : undefined }}>
        {isActive ? (
          <CheckCircle style={{ color: theme.palette.primary.main, top: 16, right: 16, position: "absolute" }} />
        ) : undefined}
        <Avatar src={img} sx={{ width: 125, height: 125, mb: 2.5 }}>
          {t(`recharge.${rechargeType}`)}
        </Avatar>
        <Typography color={colors.gray950} fontWeight={600} fontSize={"1rem"}>
          {t(`recharge.${rechargeType}`)}
        </Typography>
        <Stack direction={"row"} alignItems={"end"} spacing={0.5}>
          <Trans
            i18nKey={`recharge.create.price`}
            values={{ price: renderNumber(price, 2, !Number.isInteger(price)) }}
            components={{
              t: <Typography color={colors.gray900} fontWeight={600} fontSize={"2rem"} />,
              t1: <Typography pb={1} color={colors.gray400} fontWeight={600} fontSize={".75rem"} />,
            }}
          />
        </Stack>
        {priceWithoutTax && priceWithoutTax !== price && (
          <Typography fontSize={"0.75rem"} color={colors.gray600}>
            {t("recharge.create.withoutTax", {
              price: renderNumber(priceWithoutTax, 2, !Number.isInteger(priceWithoutTax)),
            })}
          </Typography>
        )}
        <Stack py={3} spacing={2}>
          {contains.map((value, index) => (
            <Stack key={index} spacing={1.5} direction={"row"}>
              <CheckCircleOutlined style={{ color: colors.success500 }} />
              <Typography pt={"2px"} fontSize={".875rem"} color={colors.gray600}>
                <Trans
                  i18nKey={`recharge.create.contain.${value}`}
                  components={{
                    aCashbackSats: <Link href={"/proc-bitcoin/cashback-saty"} target={"_blank"} />,
                    aDiscord: <Link href={DISCORD} target={"_blank"} />,
                  }}
                />
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Box pt={2} height={"100%"} width={"100%"} display={"flex"} alignItems={"flex-end"}>
          <Stack width={"100%"} spacing={1}>
            {userInvoiceData?.invoiceData ? (
              <Button
                disabled={isActive}
                fullWidth
                onClick={() => handleActivate(rechargeType)}
                variant="contained">
                {isActive
                  ? t(`recharge.create.${data?.state}`)
                  : t("recharge.create.redirectToPay", { price: renderNumber(price, 2, !Number.isInteger(price)) })}
              </Button>
            ) : (
              <Button fullWidth variant="contained" onClick={() => setModalInvoiceSetupOpen(true)}>
                {t("recharge.create.setInvoice")}
              </Button>
            )}
            {data?.state === RechargeState.NEW && isActive && (
              <Button
                fullWidth
                onClick={() => handleCancel()}
                variant="contained"
                color="inherit"
                sx={{ backgroundColor: colors.white, border: `1px solid ${colors.gray300}` }}>
                {t("recharge.cancelButton")}
              </Button>
            )}
          </Stack>
        </Box>
      </Card2>
    );
  };

  return (
    <>
      <PageTitle i18nKey="recharge.create.meta_title" />
      <Typography fontWeight={500} fontSize={"1.375rem"} color={colors.gray900} pb={1.5}>
        {t("recharge.create.title")}
      </Typography>
      <Typography color={colors.gray900} fontSize={".875rem"} pb={4}>
        <Trans
          i18nKey="recharge.create.subtitle"
          components={{
            a1: <Link href={"/predplatne"} />,
            a2: <Link href={"/proc-bitcoin/cashback-saty"} />,
          }}
        />
      </Typography>
      <Stack spacing={3} direction={{ xs: "column", lg: "row" }}>
        {rechargeOptions.map(({ type, defaultPrice, contains, img, prevType }) =>
          rechargeCard(type, defaultPrice, contains, img, prevType)
        )}
      </Stack>
      <Typography fontWeight={500} fontSize={"1.375rem"} color={colors.gray900} pt={4} pb={1.5}>
        {t("recharge.create.additionalTitle")}
      </Typography>
      <Typography color={colors.gray900} fontSize={".875rem"}>
        <Trans
          i18nKey="recharge.create.additionalSubtitle"
          components={{
            a: <Link href={"/podminky"} target={"_blank"} />,
          }}
        />
      </Typography>
      <BackdropLoading open={isOpenBackdropLoading} />
      {modalInvoiceSetupOpen && (
        <ModalInvoiceSetup
          open={modalInvoiceSetupOpen}
          onClose={() => setModalInvoiceSetupOpen(false)}
          onSuccess={handleRefresh}
        />
      )}
    </>
  );
};

export default NewRecharge;
