import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { BarLineChart, CoinsStacked } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPIDcaBotsList, useAPIGridBotsList } from "hooks/useAPI";
import { useIsLogin } from "hooks/useUser";
import { useIsSmallScreen } from "hooks/useWindowSize";
import { Outlet } from "react-router-dom";

import BottomNavigation from "../Main/BottomNavigation";
import MenuItem from "../Main/MenuItem";
import Footer from "./Footer";
import Header from "./Header";

const Default: React.FC = () => {
  const isLogin = useIsLogin();
  const isSmallScreen = useIsSmallScreen();
  const isBottomNavigation = Boolean(isSmallScreen && isLogin);
  const { changeMode } = useMUIThemeModeContext();

  const { data: dcaBotsData } = useAPIDcaBotsList(isLogin === true);
  const { data: botsData } = useAPIGridBotsList(isLogin === true);

  useEffect(() => changeMode("light", false), []);

  const getBotsList = () => {
    const enabledList: React.ReactNode[] = [];
    const disabledList: React.ReactNode[] = [];

    botsData?.bots.forEach((bot, index) =>
      bot.enabled
        ? enabledList.push(
            <MenuItem
              key={`BotItem-menu-${index}`}
              icon={<BarLineChart color="inherit" sx={{ fontSize: 20 }} />}
              title={bot.currencyPair}
              subtitle={bot.label}
              to={`/grid-strategie/${bot.id}`}
              withSubtitle
              withSelected
            />
          )
        : disabledList.push(
            <MenuItem
              key={`BotItem-menu-${index}`}
              icon={<BarLineChart color="inherit" sx={{ fontSize: 20 }} />}
              title={bot.currencyPair}
              subtitle={bot.label}
              to={`/grid-strategie/${bot.id}`}
              withSubtitle
              withSelected
            />
          )
    );

    dcaBotsData?.dcaBots.forEach((dcaBot, index) =>
      dcaBot.enabled
        ? enabledList.push(
            <MenuItem
              key={`DcaBotItem-menu-${index}`}
              icon={<CoinsStacked color="inherit" sx={{ fontSize: 20 }} />}
              title={dcaBot.currencyPair}
              subtitle={dcaBot.label}
              to={`/strategie/${dcaBot.id}`}
              withSubtitle
              withSelected
            />
          )
        : disabledList.push(
            <MenuItem
              key={`DcaBotItem-menu-${index}`}
              icon={<CoinsStacked color="inherit" sx={{ fontSize: 20 }} />}
              title={dcaBot.currencyPair}
              subtitle={dcaBot.label}
              to={`/strategie/${dcaBot.id}`}
              withSubtitle
              withSelected
            />
          )
    );

    return { enabledList, disabledList };
  };

  return (
    <>
      <Header />
      <Box component="main" className="main-content" pt={12}>
        <Outlet />
      </Box>
      <Footer isHome={true} />
      {isBottomNavigation && <BottomNavigation botsList={getBotsList()} />}
    </>
  );
};

export default Default;
