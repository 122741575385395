import { SvgIcon } from "@mui/material";

export const ArrowDown2 = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 13 8">
      <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5938 0.697835L6.78708 6.5045C6.7494 6.54224 6.70465 6.57217 6.65539 6.5926C6.60613 6.61302 6.55333 6.62354 6.5 6.62354C6.44667 6.62354 6.39387 6.61302 6.34461 6.5926C6.29535 6.57217 6.2506 6.54224 6.21292 6.5045L0.40625 0.697835"
          stroke="#6D6D6D"
          strokeWidth="0.8125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
