import React, { useState } from "react";

import { Avatar, Box, Collapse, IconButton, Stack, styled, Typography } from "@mui/material";
import imageSats from "assets/images/sats.png";
import Badge from "components/elements/Badge";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { ArrowDown2 } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { renderDate, renderDateCustom, renderNumber } from "utils/formatter";

import { ICashbackSatsTransactionSummary } from "../types";

interface IProps {
  summary: ICashbackSatsTransactionSummary;
  children?: React.ReactNode;
  defaultOpen?: boolean;
  isSub?: boolean;
}

const ArrowIcon = styled(ArrowDown2)<{ open?: boolean }>(({ open }) => ({
  transform: open ? "rotate(180deg)" : "rotate(0deg)",
  transition: "transform 0.3s ease",
  width: "13px",
  height: "13px",
}));

const TransactionCard: React.FC<IProps> = ({ summary, children, defaultOpen = false, isSub }) => {
  const { colors } = useMUIThemeModeContext();
  const [open, setOpen] = useState(defaultOpen);

  const getMonthDate = (date: string) => {
    if (isSub) return renderDate(date);

    const result = renderDateCustom(date, { month: "long", year: "numeric" });
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <Box>
      <Stack direction={"row"} justifyContent={"space-between"} pr={isSub ? 0 : { xs: 1, md: 3 }} alignItems={"center"}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={{ xs: 1, md: 2 }}
          alignItems={{ xs: "start", md: "center" }}>
          <Typography color={colors.black} fontWeight={600} fontSize={"1rem"}>
            {getMonthDate(summary.d)}
          </Typography>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {summary.dCount ? (
              <Badge color="success">
                <Typography fontSize={"0.75rem"} fontWeight={500}>
                  {`${summary.dCount}×`}
                </Typography>
              </Badge>
            ) : null}
            {summary.gCount ? (
              <Badge color="info">
                <Typography fontSize={"0.75rem"} fontWeight={500}>
                  {`${summary.gCount}×`}
                </Typography>
              </Badge>
            ) : null}
            {summary.aCount ? (
              <Badge color="purple">
                <Typography fontSize={"0.75rem"} fontWeight={500}>
                  {`${summary.aCount}×`}
                </Typography>
              </Badge>
            ) : null}
          </Stack>
        </Stack>
        <Stack direction={"row"} spacing={{ xs: 1, md: 3 }} alignItems={"center"}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <MaskedModeWrapper color={colors.darkBlue} fontWeight={600} fontSize={".875rem"}>
              <Typography color={colors.darkBlue} fontWeight={600} fontSize={".875rem"}>
                {`+${renderNumber(summary.sum)}`}
              </Typography>
            </MaskedModeWrapper>
            <Avatar src={imageSats} sx={{ width: "16px", height: "16px" }} />
          </Stack>
          <IconButton size={"small"} onClick={() => setOpen(!open)}>
            <ArrowIcon open={open} />
          </IconButton>
        </Stack>
      </Stack>
      <Collapse in={open} timeout="auto">
        {children}
      </Collapse>
    </Box>
  );
};

export default TransactionCard;
