import { AFFILIATE } from "constants/links";

import React from "react";

import { ChevronRight } from "@mui/icons-material";
import { Avatar, Box, Button, Grid, Stack, Typography, useTheme, Link as MuiLink } from "@mui/material";
import imageBitclub from "assets/images/partners/bitclub.jpg";
import imageBitcoinSfera from "assets/images/partners/bitcoin-sfera.png";
import imageBitcoinVKapse from "assets/images/partners/bitcoin-v-kapse.jpg";
import imageBitcoinovaSkola from "assets/images/partners/bitcoinova-skola.png";
import imageBitcoinovejKanal from "assets/images/partners/bitcoinovej-kanal.png";
import imageBitperia from "assets/images/partners/bitperia.png";
import imageCasJsouPenize from "assets/images/partners/cas-jsou-penize.png";
import imageDedaBitcoiner from "assets/images/partners/deda-bitcoiner.png";
import imageKryptoguru from "assets/images/partners/kryptoguru.png";
import imageKryptoplebs from "assets/images/partners/kryptoplebs.png";
import imageOndrejKobersky from "assets/images/partners/ondrej-kobersky.png";
import imageZaujaloMa from "assets/images/partners/zaujalo-ma.png";
import HomeBox from "components/modules/HomeBox";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ITEMS = [
  {
    name: "Jakub ‘Kicom’ Vejmola",
    title: "Bitcoinovej kanál",
    photo: imageBitcoinovejKanal,
    link: "https://www.youtube.com/@BTCKNL",
  },
  {
    name: "Johnny The Bitcoiner",
    title: "Kryptoplebs",
    photo: imageKryptoplebs,
    link: "https://www.youtube.com/@kryptoplebs",
  },
  {
    name: "Tomáš Krause",
    title: "Bitcoin v kapse",
    photo: imageBitcoinVKapse,
    link: "https://bitcoinvkapse.cz/",
  },
  {
    name: "Martin Gregor",
    title: "Zaujalo ma",
    photo: imageZaujaloMa,
    link: "https://www.youtube.com/@zaujaloma",
  },
  {
    name: "Ďuri",
    title: "Bitcoin Sféra",
    photo: imageBitcoinSfera,
    link: "https://www.youtube.com/@bitcoin_sfera",
  },
  {
    name: "Ondřej Řepík",
    title: "Čas jsou peníze",
    photo: imageCasJsouPenize,
    link: "https://www.youtube.com/@CasjsouPenize",
  },
  {
    name: "Ondřej Koběrský",
    title: "Finanční youtuber",
    photo: imageOndrejKobersky,
    link: "https://www.youtube.com/c/Ond%C5%99ejKob%C4%9Brsk%C3%BD",
  },
  {
    name: "Děda Bitcoiner",
    title: "",
    photo: imageDedaBitcoiner,
    link: "https://www.youtube.com/@Dedabitcoiner",
  },
  {
    name: "Bitperia.cz",
    title: "Bitcoinový průvodce",
    photo: imageBitperia,
    link: "https://bitperia.cz",
  },
  {
    name: "KryptoGuru.cz",
    title: "Krypto magazín",
    photo: imageKryptoguru,
    link: "https://kryptoguru.cz/",
  },
  {
    name: "Bitclub Kolín",
    title: "Bitcoinová kavárna",
    photo: imageBitclub,
    link: "https://x.com/bitclub_kolin",
  },
  {
    name: "Dominik Žažo",
    title: "Bitcoinová Škola",
    photo: imageBitcoinovaSkola,
    link: "https://www.bitcoinovaskola.sk/",
  },
];

const AffiliateProgram: React.FC = () => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();

  return (
    <HomeBox textAlign={"start"}>
      <Grid container spacing={{ xs: 3, md: 5 }}>
        <Grid item xs={12} lg={4}>
          <Typography color={theme.palette.tertiary.main} fontWeight={600} fontSize={{ xs: "0.9rem", sm: "1rem" }}>
            {t("homepage.afiliateProgram.name")}
          </Typography>
          <Typography
            pt={{ xs: 1, sm: 3 }}
            fontSize={{ xs: "1.25rem", sm: "1.5rem", lg: "2rem" }}
            lineHeight={"145%"}
            fontWeight={600}
            color={"black"}>
            {t("homepage.afiliateProgram.title")}
          </Typography>
          <Typography
            pt={{ xs: 1, sm: 3 }}
            fontSize={{ xs: "0.8rem", sm: "1.125rem" }}
            lineHeight={"155%"}
            fontWeight={300}
            color={colors["blueGray"]}>
            <Trans i18nKey={"homepage.afiliateProgram.subtitle"} />
          </Typography>
          <Button
            sx={{ mt: { xs: 2, sm: 6 } }}
            variant="contained"
            endIcon={<ChevronRight />}
            to={AFFILIATE}
            component={Link}
            target="_blank">
            {t("homepage.afiliateProgram.button")}
          </Button>
        </Grid>
        <Grid item xs={12} lg={8} alignContent={"center"}>
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            columnGap={{ xs: 2, md: 4 }}
            rowGap={{ xs: 2, md: 4 }}
            justifyContent={"center"}>
            {ITEMS.map((item, index) => (
              <MuiLink
                key={index}
                href={item.link}
                target="_blank"
                rel="noopener"
                style={{ textDecoration: "none" }}>
                <Box
                  bgcolor={colors["blueWhite"]}
                  px={3}
                  py={2}
                  borderRadius={"99px"}
                  display={"flex"}
                  alignItems={"center"}>
                  <Avatar src={item.photo} sx={{ width: 48, height: 48 }}>
                    {item.name}
                  </Avatar>
                  <Stack pl={1.25}>
                    <Typography
                      fontSize={{ xs: "0.8rem", sm: "1rem" }}
                      lineHeight={"155%"}
                      fontWeight={600}
                      color={colors["deepMidnightBlue"]}>
                      {item.name}
                    </Typography>
                    <Typography
                      fontSize={{ xs: "0.7rem", sm: "0.8rem" }}
                      lineHeight={"155%"}
                      fontWeight={400}
                      color={colors["blueGray"]}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Box>
              </MuiLink>
            ))}
          </Box>
        </Grid>
      </Grid>
    </HomeBox>
  );
};

export default AffiliateProgram;
